import actions from './actions';

const {
  SET_SETTINGS_SUCCESS,
  SET_SETTINGS_ERR,
  SET_SETTINGS_BEGIN,
  GET_SETTINGS_BEGIN,
  GET_SETTINGS_ERR,
  GET_SETTINGS_SUCCESS,
} = actions;

const initState = {
  loading: false,
  value: null,
  status: null,
};

const SettingsReducer = (state = initState, action) => {
  const { type, data, status } = action;

  switch (type) {
    case SET_SETTINGS_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_SETTINGS_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case SET_SETTINGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        status,
        value: data,
      };
    }
    case GET_SETTINGS_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_SETTINGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        value: data,
        status: null,
      };
    }
    case GET_SETTINGS_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    default:
      return state;
  }
};

export default SettingsReducer;
