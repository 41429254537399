import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const Media = lazy(() => import('../../container/media/Media'));
const Settings = lazy(() => import('../../container/settings/Settings'));

const PagesRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/media`} component={Media} />
      <Route path={`${path}/404`} component={NotFound} />
      <Route path={`${path}/page-settings`} component={Settings} />
    </Switch>
  );
};

export default PagesRoute;
