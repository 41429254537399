import actions from './actions';

const {
  ADD_CATEGORY_BEGIN,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_ERR,
  GET_CATEGORIES_BEGIN,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_ERR,
  ADD_NEWS_BEGIN,
  ADD_NEWS_SUCCESS,
  ADD_NEWS_ERR,
  GET_NEWS_BEGIN,
  GET_NEWS_SUCCESS,
  GET_NEWS_ERR,
  GET_ONE_NEWS_BEGIN,
  GET_ONE_NEWS_SUCCESS,
  GET_ONE_NEWS_ERR,
  EDIT_NEWS_BEGIN,
  EDIT_NEWS_SUCCESS,
  EDIT_NEWS_ERR,
  DELETE_NEWS_BEGIN,
  DELETE_NEWS_SUCCESS,
  DELETE_NEWS_ERR,
} = actions;

const initState = {
  loadingCategories: false,
  loadingNews: false,
  loadingData: false,
  categories: [],
  news: [],
  status: null,
  toEdit: null,
};

const NewsReducer = (state = initState, action) => {
  const { type, data, status } = action;

  switch (type) {
    // ADD CATEGORY CASES

    case ADD_CATEGORY_BEGIN: {
      return {
        ...state,
        loadingCategories: true,
      };
    }
    case ADD_CATEGORY_SUCCESS: {
      return {
        ...state,
        loadingCategories: false,
        categories: data,
        status,
      };
    }
    case ADD_CATEGORY_ERR: {
      return {
        ...state,
        loadingCategories: false,
        status,
      };
    }

    // GET CATEGORIES CASES

    case GET_CATEGORIES_BEGIN: {
      return {
        ...state,
        loadingCategories: true,
      };
    }
    case GET_CATEGORIES_SUCCESS: {
      return {
        ...state,
        loadingCategories: false,
        categories: data,
        status: null,
      };
    }
    case GET_CATEGORIES_ERR: {
      return {
        ...state,
        loadingCategories: false,
        status,
      };
    }

    // ADD NEWS CASES

    case ADD_NEWS_BEGIN: {
      return {
        ...state,
        loadingNews: true,
      };
    }
    case ADD_NEWS_SUCCESS: {
      return {
        ...state,
        loadingNews: false,
        status,
      };
    }
    case ADD_NEWS_ERR: {
      return {
        ...state,
        loadingNews: false,
        status,
      };
    }

    // GET NEWS CASES

    case GET_NEWS_BEGIN: {
      return {
        ...state,
        loadingNews: true,
      };
    }
    case GET_NEWS_SUCCESS: {
      return {
        ...state,
        loadingNews: false,
        news: data,
        status: null,
      };
    }
    case GET_NEWS_ERR: {
      return {
        ...state,
        loadingNews: false,
        status,
      };
    }

    // GET ONE NEWS CASES

    case GET_ONE_NEWS_BEGIN: {
      return {
        ...state,
        loadingData: true,
      };
    }
    case GET_ONE_NEWS_SUCCESS: {
      return {
        ...state,
        loadingData: false,
        toEdit: data,
        status: null,
      };
    }
    case GET_ONE_NEWS_ERR: {
      return {
        ...state,
        loadingData: false,
        status,
      };
    }

    // EDIT NEWS CASES

    case EDIT_NEWS_BEGIN: {
      return {
        ...state,
        loadingNews: true,
      };
    }
    case EDIT_NEWS_SUCCESS: {
      return {
        ...state,
        loadingNews: false,
        toEdit: data,
        status,
      };
    }
    case EDIT_NEWS_ERR: {
      return {
        ...state,
        loadingNews: false,
        status,
      };
    }

    // DELETE NEWS CASES

    case DELETE_NEWS_BEGIN: {
      return {
        ...state,
        loadingNews: true,
      };
    }
    case DELETE_NEWS_SUCCESS: {
      return {
        ...state,
        loadingNews: false,
        news: data,
        status,
      };
    }
    case DELETE_NEWS_ERR: {
      return {
        ...state,
        loadingNews: false,
        status,
      };
    }
    default:
      return state;
  }
};

export { NewsReducer };
