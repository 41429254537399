import actions from './actions';

const {
  ADD_TEAM_BEGIN,
  ADD_TEAM_ERR,
  ADD_TEAM_SUCCESS,
  GET_TEAMS_SUCCESS,
  GET_TEAMS_BEGIN,
  GET_TEAMS_ERR,
  GET_LEAGUE_TEAMS_BEGIN,
  GET_LEAGUE_TEAMS_SUCCESS,
  GET_LEAGUE_TEAMS_ERR,
  UPDATE_TEAM_BEGIN,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TEAM_ERR,
  DELETE_TEAM_BEGIN,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_ERR,
  DELETE_SEASON_BEGIN,
  DELETE_SEASON_SUCCESS,
  DELETE_SEASON_ERR,
} = actions;

const initState = {
  loading: false,
  list: [],
  status: null,
  leagueTeams: [],
  ltLoading: false,
  ltStatus: null,
};

const TeamReducer = (state = initState, action) => {
  const { type, data, status } = action;

  switch (type) {
    case ADD_TEAM_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADD_TEAM_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case ADD_TEAM_SUCCESS: {
      return {
        ...state,
        loading: false,
        status,
        list: data,
      };
    }
    case GET_LEAGUE_TEAMS_BEGIN: {
      return {
        ...state,
        ltLoading: true,
      };
    }
    case GET_LEAGUE_TEAMS_SUCCESS: {
      return {
        ...state,
        ltLoading: false,
        leagueTeams: data,
        ltStatus: null,
      };
    }
    case GET_LEAGUE_TEAMS_ERR: {
      return {
        ...state,
        ltLoading: false,
        ltStatus: status,
      };
    }
    case GET_TEAMS_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_TEAMS_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: data,
        status: null,
      };
    }
    case GET_TEAMS_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case UPDATE_TEAM_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_TEAM_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: data,
        status,
      };
    }
    case UPDATE_TEAM_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case DELETE_TEAM_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_TEAM_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: data,
        status,
      };
    }
    case DELETE_TEAM_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case DELETE_SEASON_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_SEASON_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: data,
        status,
      };
    }
    case DELETE_SEASON_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    default:
      return state;
  }
};

export default TeamReducer;
