import { notification } from 'antd';

const sendNotification = (status, message) => {
  notification[status]({
    message: status == 'success' ? 'Udało się' : 'Błąd',
    description: message,
  });
};

const actions = {
  ADD_PAGE_BEGIN: 'ADD_PAGE_BEGIN',
  ADD_PAGE_SUCCESS: 'ADD_PAGE_SUCCESS',
  ADD_PAGE_ERR: 'ADD_PAGE_ERR',

  GET_ONE_PAGE_BEGIN: 'GET_ONE_PAGE_BEGIN',
  GET_ONE_PAGE_SUCCESS: 'GET_ONE_PAGE_SUCCESS',
  GET_ONE_PAGE_ERR: 'GET_ONE_PAGE_ERR',

  EDIT_PAGE_BEGIN: 'EDIT_PAGE_BEGIN',
  EDIT_PAGE_SUCCESS: 'EDIT_PAGE_SUCCESS',
  EDIT_PAGE_ERR: 'EDIT_PAGE_ERR',

  GET_PAGES_BEGIN: 'GET_PAGES_BEGIN',
  GET_PAGES_SUCCESS: 'GET_PAGES_SUCCESS',
  GET_PAGES_ERR: 'GET_PAGES_ERR',

  DELETE_PAGE_BEGIN: 'DELETE_PAGE_BEGIN',
  DELETE_PAGE_SUCCESS: 'DELETE_PAGE_SUCCESS',
  DELETE_PAGE_ERR: 'DELETE_PAGE_ERR',

  addPageBegin: () => {
    return {
      type: actions.ADD_PAGE_BEGIN,
    };
  },
  addPageSuccess: () => {
    return {
      type: actions.ADD_PAGE_SUCCESS,
      status,
    };
  },
  addPageErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.ADD_PAGE_ERR,
      status,
    };
  },

  getPagesBegin: () => {
    return {
      type: actions.GET_PAGES_BEGIN,
    };
  },
  getPagesSuccess: data => {
    return {
      type: actions.GET_PAGES_SUCCESS,
      data,
    };
  },
  getPagesErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.GET_PAGES_ERR,
      status,
    };
  },

  getOnePageBegin: () => {
    return {
      type: actions.GET_ONE_PAGE_BEGIN,
    };
  },
  getOnePageSuccess: data => {
    return {
      type: actions.GET_ONE_PAGE_SUCCESS,
      data,
    };
  },
  getOnePageErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.GET_ONE_PAGE_ERR,
      status,
    };
  },

  editPageBegin: () => {
    return {
      type: actions.EDIT_PAGE_BEGIN,
    };
  },
  editPageSuccess: (data, status) => {
    return {
      type: actions.EDIT_PAGE_SUCCESS,
      data,
      status,
    };
  },
  editPageErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.EDIT_PAGE_ERR,
      status,
    };
  },

  deletePageBegin: () => {
    return {
      type: actions.DELETE_PAGE_BEGIN,
    };
  },
  deletePageSuccess: (data, status) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.DELETE_PAGE_SUCCESS,
      data,
      status,
    };
  },
  deletePageErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.DELETE_PAGE_ERR,
      status,
    };
  },
};

export default actions;
