import { notification } from 'antd';

const sendNotification = (status, message) => {
  notification[status]({
    message: status == 'success' ? 'Udało się' : 'Błąd',
    description: message,
  });
};

const actions = {
  ADD_CLIENT_SUCCESS: 'ADD_CLIENT_SUCCESS',
  ADD_CLIENT_ERR: 'ADD_CLIENT_ERR',
  ADD_CLIENT_BEGIN: 'ADD_CLIENT_BEGIN',

  GET_CLIENTS_BEGIN: 'GET_CLIENTS_BEGIN',
  GET_CLIENTS_ERR: 'GET_CLIENTS_ERR',
  GET_CLIENTS_SUCCESS: 'GET_CLIENTS_SUCCESS',

  UPDATE_CLIENT_BEGIN: 'UPDATE_CLIENT_BEGIN',
  UPDATE_CLIENT_SUCCESS: 'UPDATE_CLIENT_SUCCESS',
  UPDATE_CLIENT_ERR: 'UPDATE_CLIENT_ERR',

  DELETE_CLIENT_BEGIN: 'DELETE_CLIENT_BEGIN',
  DELETE_CLIENT_SUCCESS: 'DELETE_CLIENT_SUCCESS',
  DELETE_CLIENT_ERR: 'DELETE_CLIENT_ERR',

  addClientBegin: () => {
    return {
      type: actions.ADD_CLIENT_BEGIN,
    };
  },
  addClientErr: status => {
    return {
      type: actions.ADD_CLIENT_ERR,
      status,
    };
  },
  addClientSuccess: status => {
    return {
      type: actions.ADD_CLIENT_SUCCESS,
      status,
    };
  },
  getClientsBegin: () => {
    return {
      type: actions.GET_CLIENTS_BEGIN,
    };
  },
  getClientsErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.GET_CLIENTS_ERR,
      status,
    };
  },
  getClientsSuccess: data => {
    return {
      type: actions.GET_CLIENTS_SUCCESS,
      data,
    };
  },
  updateClientBegin: () => {
    return {
      type: actions.UPDATE_CLIENT_BEGIN,
    };
  },
  updateClientSuccess: (data, status) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.UPDATE_CLIENT_SUCCESS,
      data,
      status,
    };
  },
  updateClientErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.UPDATE_CLIENT_ERR,
      status,
    };
  },
  deleteClientBegin: () => {
    return {
      type: actions.DELETE_CLIENT_BEGIN,
    };
  },
  deleteClientSuccess: (data, status) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.DELETE_CLIENT_SUCCESS,
      data,
      status,
    };
  },
  deleteClientErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.DELETE_CLIENT_ERR,
      status,
    };
  },
};

export default actions;
