import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const Matchroom = lazy(() => import('../../container/matchroom/Matchroom'));
const AddMatchroom = lazy(() => import('../../container/matchroom/Add/Index'));

const PagesRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      {/* <Route path={`${path}/add-user`} component={AddUser} /> */}
      <Route path={`${path}/add-matchroom`} component={AddMatchroom} />
      <Route path={`${path}/edit/:id`} component={AddMatchroom} />
      <Route exact path={`${path}`} component={Matchroom} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default PagesRoute;
