import { notification } from 'antd';

const sendNotification = (status, message) => {
  notification[status]({
    message: status == 'success' ? 'Udało się' : 'Błąd',
    description: message,
  });
};

const actions = {
  ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
  ADD_USER_ERR: 'ADD_USER_ERR',
  ADD_USER_BEGIN: 'ADD_USER_BEGIN',

  GET_USERS_BEGIN: 'GET_USERS_BEGIN',
  GET_USERS_ERR: 'GET_USERS_ERR',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',

  UPDATE_USER_BEGIN: 'UPDATE_USER_BEGIN',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_ERR: 'UPDATE_USER_ERR',

  DELETE_USER_BEGIN: 'DELETE_USER_BEGIN',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_ERR: 'DELETE_USER_ERR',

  addUserBegin: () => {
    return {
      type: actions.ADD_USER_BEGIN,
    };
  },
  addUserErr: status => {
    return {
      type: actions.ADD_USER_ERR,
      status,
    };
  },
  addUserSuccess: status => {
    return {
      type: actions.ADD_USER_SUCCESS,
      status,
    };
  },
  getUsersBegin: () => {
    return {
      type: actions.GET_USERS_BEGIN,
    };
  },
  getUsersErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.GET_USERS_ERR,
      status,
    };
  },
  getUsersSuccess: data => {
    return {
      type: actions.GET_USERS_SUCCESS,
      data,
    };
  },
  updateUserBegin: () => {
    return {
      type: actions.UPDATE_USER_BEGIN,
    };
  },
  updateUserSuccess: (data, status) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.UPDATE_USER_SUCCESS,
      data,
      status,
    };
  },
  updateUserErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.UPDATE_USER_ERR,
      status,
    };
  },
  deleteUserBegin: () => {
    return {
      type: actions.DELETE_USER_BEGIN,
    };
  },
  deleteUserSuccess: (data, status) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.DELETE_USER_SUCCESS,
      data,
      status,
    };
  },
  deleteUserErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.DELETE_USER_ERR,
      status,
    };
  },
};

export default actions;
