import { notification } from 'antd';

const sendNotification = (status, message) => {
  notification[status]({
    message: status == 'success' ? 'Udało się' : 'Błąd',
    description: message,
  });
};

const actions = {
  ADD_FOLDER_SUCCESS: 'ADD_FOLDER_SUCCESS',
  ADD_FOLDER_ERR: 'ADD_FOLDER_ERR',
  ADD_FOLDER_BEGIN: 'ADD_FOLDER_BEGIN',

  GET_FOLDER_LIST_BEGIN: 'GET_FOLDER_LIST_BEGIN',
  GET_FOLDER_LIST_ERR: 'GET_FOLDER_LIST_ERR',
  GET_FOLDER_LIST_SUCCESS: 'GET_FOLDER_LIST_SUCCESS',

  ADD_FILES_BEGIN: 'ADD_FILES_BEGIN',
  ADD_FILES_SUCCESS: 'ADD_FILES_SUCCESS',
  ADD_FILES_ERR: 'ADD_FILES_ERR',

  GET_FILE_LIST_BEGIN: 'GET_FILE_LIST_BEGIN',
  GET_FILE_LIST_SUCCESS: 'GET_FILE_LIST_SUCCESS',
  GET_FILE_LIST_ERR: 'GET_FILE_LIST_ERR',

  DELETE_FILE_BEGIN: 'DELETE_FILE_BEGIN',
  DELETE_FILE_SUCCESS: 'DELETE_FILE_SUCCESS',
  DELETE_FILE_ERR: 'DELETE_FILE_ERR',

  CHANGE_SELECTION: 'CHANGE_SELECTION',

  changeSelectionAction: selection => {
    return {
      type: actions.CHANGE_SELECTION,
      selected: selection,
    };
  },

  getFileListBegin: () => {
    return {
      type: actions.GET_FILE_LIST_BEGIN,
    };
  },
  getFileListErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.GET_FILE_LIST_ERR,
      status,
    };
  },
  getFileListSuccess: data => {
    return {
      type: actions.GET_FILE_LIST_SUCCESS,
      data,
    };
  },

  deleteFileBegin: () => {
    return {
      type: actions.DELETE_FILE_BEGIN,
    };
  },
  deleteFileSuccess: (data, status) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.DELETE_FILE_SUCCESS,
      status,
      data,
    };
  },
  deleteFileErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.DELETE_FILE_ERR,
      status,
    };
  },

  addFilesBegin: () => {
    return {
      type: actions.ADD_FILES_BEGIN,
    };
  },
  addFilesSuccess: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    const data = status.files;

    return {
      type: actions.ADD_FILES_SUCCESS,
      status,
      data,
    };
  },
  addFilesErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.ADD_FILES_ERR,
      status,
    };
  },

  addFolderBegin: () => {
    return {
      type: actions.ADD_FOLDER_BEGIN,
    };
  },
  addFolderErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.ADD_FOLDER_ERR,
      status,
    };
  },
  addFolderSuccess: (data, status) => {
    sendNotification(status.status, status.message);

    return {
      type: actions.ADD_FOLDER_SUCCESS,
      data,
      status,
    };
  },

  getFolderListBegin: () => {
    return {
      type: actions.GET_FOLDER_LIST_BEGIN,
    };
  },
  getFolderListErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.GET_FOLDER_LIST_ERR,
      status,
    };
  },
  getFolderListSuccess: data => {
    return {
      type: actions.GET_FOLDER_LIST_SUCCESS,
      data,
    };
  },
};

export default actions;
