import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const News = lazy(() => import('../../container/pages/News'));
const AddNews = lazy(() => import('../../container/pages/AddNews'));

const PagesRoute = () => {
  const { path } = useRouteMatch();
  console.log(path);

  return (
    <Switch>
      <Route path={`${path}/edit/:id`} component={AddNews} />
      <Route path={`${path}/add-news`} component={AddNews} />
      <Route exact path={`${path}`} component={News} />
    </Switch>
  );
};

export default PagesRoute;
