import { notification } from 'antd';

const sendNotification = (status, message) => {
  notification[status]({
    message: status == 'success' ? 'Udało się' : 'Błąd',
    description: message,
  });
};

const actions = {
  GET_PRICELIST_BEGIN: 'GET_PRICELIST_BEGIN',
  GET_PRICELIST_SUCCESS: 'GET_PRICELIST_SUCCESS',
  GET_PRICELIST_ERR: 'GET_PRICELIST_ERR',

  ADD_PRICELIST_SUCCESS: 'ADD_PRICELIST_SUCCESS',
  ADD_PRICELIST_ERR: 'ADD_PRICELIST_ERR',
  ADD_PRICELIST_BEGIN: 'ADD_PRICELIST_BEGIN',

  UPDATE_PRICELIST_BEGIN: 'UPDATE_PRICELIST_BEGIN',
  UPDATE_PRICELIST_SUCCESS: 'UPDATE_PRICELIST_SUCCESS',
  UPDATE_PRICELIST_ERR: 'UPDATE_PRICELIST_ERR',

  DELETE_PRICELIST_BEGIN: 'DELETE_PRICELIST_BEGIN',
  DELETE_PRICELIST_SUCCESS: 'DELETE_PRICELIST_SUCCESS',
  DELETE_PRICELIST_ERR: 'DELETE_PRICELIST_ERR',

  ADD_PRICE_ELEMENT_SUCCESS: 'ADD_PRICE_ELEMENT_SUCCESS',
  ADD_PRICE_ELEMENT_ERR: 'ADD_PRICE_ELEMENT_ERR',
  ADD_PRICE_ELEMENT_BEGIN: 'ADD_PRICE_ELEMENT_BEGIN',

  UPDATE_PRICE_ELEMENT_BEGIN: 'UPDATE_PRICE_ELEMENT_BEGIN',
  UPDATE_PRICE_ELEMENT_SUCCESS: 'UPDATE_PRICE_ELEMENT_SUCCESS',
  UPDATE_PRICE_ELEMENT_ERR: 'UPDATE_PRICE_ELEMENT_ERR',

  DELETE_PRICE_ELEMENT_BEGIN: 'DELETE_PRICE_ELEMENT_BEGIN',
  DELETE_PRICE_ELEMENT_SUCCESS: 'DELETE_PRICE_ELEMENT_SUCCESS',
  DELETE_PRICE_ELEMENT_ERR: 'DELETE_PRICE_ELEMENT_ERR',

  getPriceListBegin: () => {
    return {
      type: actions.GET_PRICELIST_BEGIN,
    };
  },
  getPriceListSuccess: data => {
    return {
      type: actions.GET_PRICELIST_SUCCESS,
      data,
    };
  },
  getPriceListErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.GET_PRICELIST_ERR,
      status,
    };
  },

  addPriceListBegin: () => {
    return {
      type: actions.ADD_PRICELIST_BEGIN,
    };
  },
  addPriceListErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.ADD_PRICELIST_ERR,
      status,
    };
  },
  addPriceListSuccess: (status, data) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.ADD_PRICELIST_SUCCESS,
      status,
      data,
    };
  },

  updatePriceListBegin: () => {
    return {
      type: actions.UPDATE_PRICELIST_BEGIN,
    };
  },
  updatePriceListSuccess: (data, status) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.UPDATE_PRICELIST_SUCCESS,
      data,
      status,
    };
  },
  updatePriceListErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.UPDATE_PRICELIST_ERR,
      status,
    };
  },

  deletePriceListBegin: () => {
    return {
      type: actions.DELETE_PRICELIST_BEGIN,
    };
  },
  deletePriceListSuccess: (data, status) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.DELETE_PRICELIST_SUCCESS,
      data,
      status,
    };
  },
  deletePriceListErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.DELETE_PRICELIST_ERR,
      status,
    };
  },

  addPriceElementBegin: () => {
    return {
      type: actions.ADD_PRICE_ELEMENT_BEGIN,
    };
  },
  addPriceElementErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.ADD_PRICE_ELEMENT_ERR,
      status,
    };
  },
  addPriceElementSuccess: (status, data) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.ADD_PRICE_ELEMENT_SUCCESS,
      status,
      data,
    };
  },

  updatePriceElementBegin: () => {
    return {
      type: actions.UPDATE_PRICE_ELEMENT_BEGIN,
    };
  },
  updatePriceElementSuccess: (data, status) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.UPDATE_PRICE_ELEMENT_SUCCESS,
      data,
      status,
    };
  },
  updatePriceElementErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.UPDATE_PRICE_ELEMENT_ERR,
      status,
    };
  },

  deletePriceElementBegin: () => {
    return {
      type: actions.DELETE_PRICE_ELEMENT_BEGIN,
    };
  },
  deletePriceElementSuccess: (data, status) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.DELETE_PRICE_ELEMENT_SUCCESS,
      data,
      status,
    };
  },
  deletePriceElementErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.DELETE_PRICE_ELEMENT_ERR,
      status,
    };
  },
};

export default actions;
