import { notification } from 'antd';

const sendNotification = (status, message) => {
  notification[status]({
    message: status == 'success' ? 'Udało się' : 'Błąd',
    description: message,
  });
};

const actions = {
  SET_SETTINGS_SUCCESS: 'SET_SETTINGS_SUCCESS',
  SET_SETTINGS_ERR: 'SET_SETTINGS_ERR',
  SET_SETTINGS_BEGIN: 'SET_SETTINGS_BEGIN',

  GET_SETTINGS_BEGIN: 'GET_SETTINGS_BEGIN',
  GET_SETTINGS_ERR: 'GET_SETTINGS_ERR',
  GET_SETTINGS_SUCCESS: 'GET_SETTINGS_SUCCESS',

  getSettingsBegin: () => {
    return {
      type: actions.GET_SETTINGS_BEGIN,
    };
  },
  getSettingsErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.GET_SETTINGS_ERR,
      status,
    };
  },
  getSettingsSuccess: data => {
    return {
      type: actions.GET_SETTINGS_SUCCESS,
      data,
    };
  },

  setSettingsBegin: () => {
    return {
      type: actions.SET_SETTINGS_BEGIN,
    };
  },
  setSettingsSuccess: (data, status) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.SET_SETTINGS_SUCCESS,
      data,
      status,
    };
  },
  setSettingsErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.SET_SETTINGS_ERR,
      status,
    };
  },
};

export default actions;
