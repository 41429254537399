import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Movies = lazy(() => import('../../container/movies/Movies'));
const NotFound = lazy(() => import('../../container/pages/404'));

const PagesRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      {/* <Route path={`${path}/add-user`} component={AddUser} /> */}
      <Route exact path={`${path}`} component={Movies} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default PagesRoute;
