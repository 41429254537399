import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Players = lazy(() => import('../../container/players/Players'));
const AddPlayer = lazy(() => import('../../container/players/AddPlayer'));
const NotFound = lazy(() => import('../../container/pages/404'));

const PagesRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/add-player`} component={AddPlayer} />
      <Route exact path={`${path}`} component={Players} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default PagesRoute;
