import { notification } from 'antd';

const sendNotification = (status, message) => {
  notification[status]({
    message: status == 'success' ? 'Udało się' : 'Błąd',
    description: message,
  });
};

const actions = {
  LOGIN_BEGIN: 'LOGIN_BEGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERR: 'LOGIN_ERR',

  LOGOUT_BEGIN: 'LOGOUT_BEGIN',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERR: 'LOGOUT_ERR',

  REFRESH_BEGIN: 'REFRESH_BEGIN',
  REFRESH_SUCCESS: 'REFRESH_SUCCESS',
  REFRESH_ERR: 'REFRESH_ERR',

  PASSWORD_RESET_BEGIN: 'PASSWORD_RESET_BEGIN',
  PASSWORD_RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
  PASSWORD_RESET_ERR: 'PASSWORD_RESET_ERR',

  RESET_CLIENT_PASSWORD_BEGIN: 'RESET_CLIENT_PASSWORD_BEGIN',
  RESET_CLIENT_PASSWORD_SUCCESS: 'RESET_CLIENT_PASSWORD_SUCCESS',
  RESET_CLIENT_PASSWORD_ERR: 'RESET_CLIENT_PASSWORD_ERR',

  loginBegin: () => {
    return {
      type: actions.LOGIN_BEGIN,
    };
  },

  loginSuccess: data => {
    return {
      type: actions.LOGIN_SUCCESS,
      data,
    };
  },

  loginErr: err => {
    return {
      type: actions.LOGIN_ERR,
      err,
    };
  },

  logoutBegin: () => {
    return {
      type: actions.LOGOUT_BEGIN,
    };
  },

  logoutSuccess: data => {
    return {
      type: actions.LOGOUT_SUCCESS,
      data,
    };
  },

  logoutErr: err => {
    return {
      type: actions.LOGOUT_ERR,
      err,
    };
  },

  refreshBegin: () => {
    return {
      type: actions.REFRESH_BEGIN,
    };
  },

  refreshSuccess: data => {
    return {
      type: actions.REFRESH_SUCCESS,
      data,
    };
  },

  refreshErr: err => {
    if (err.payload) sendNotification(err.status, err.message);

    return {
      type: actions.REFRESH_ERR,
      err,
    };
  },

  resetPasswordBegin: () => {
    return {
      type: actions.PASSWORD_RESET_BEGIN,
    };
  },

  resetPasswordSuccess: data => {
    sendNotification(data.status, data.message);
    return {
      type: actions.PASSWORD_RESET_SUCCESS,
      data,
    };
  },

  resetPasswordErr: err => {
    if (err.payload) sendNotification(err.status, err.message);

    return {
      type: actions.PASSWORD_RESET_ERR,
      err,
    };
  },

  resetClientPasswordBegin: () => {
    return {
      type: actions.RESET_CLIENT_PASSWORD_BEGIN,
    };
  },

  resetClientPasswordSuccess: data => {
    if (!data.payload) sendNotification(data.status, data.message);

    return {
      type: actions.RESET_CLIENT_PASSWORD_SUCCESS,
      data,
    };
  },

  resetClientPasswordErr: err => {
    if (!err.payload) sendNotification(err.status, err.message);

    return {
      type: actions.RESET_CLIENT_PASSWORD_ERR,
      err,
    };
  },
};

export default actions;
