import actions from './actions';

const {
  ADD_PLAYER_BEGIN,
  ADD_PLAYER_ERR,
  ADD_PLAYER_SUCCESS,
  GET_PLAYERS_SUCCESS,
  GET_PLAYERS_BEGIN,
  GET_PLAYERS_ERR,
  UPDATE_PLAYER_BEGIN,
  UPDATE_PLAYER_SUCCESS,
  UPDATE_PLAYER_ERR,

  SELECT_PLAYER_BEGIN,
  SELECT_PLAYER_SUCCESS,
  SELECT_PLAYER_ERR,

  DELETE_PLAYER_BEGIN,
  DELETE_PLAYER_SUCCESS,
  DELETE_PLAYER_ERR,

  GET_API_PLAYERS_SUCCESS,
  GET_API_PLAYERS_BEGIN,
  GET_API_PLAYERS_ERR,
} = actions;

const initState = {
  loading: false,
  list: [],
  status: null,
  optaPlayers: [],
  loadingData: false,
};

const PlayerReducer = (state = initState, action) => {
  const { type, data, status } = action;

  switch (type) {
    case ADD_PLAYER_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADD_PLAYER_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case ADD_PLAYER_SUCCESS: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case GET_PLAYERS_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_PLAYERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: data,
        status: null,
      };
    }
    case GET_PLAYERS_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case GET_API_PLAYERS_BEGIN: {
      return {
        ...state,
        loadingData: true,
      };
    }
    case GET_API_PLAYERS_SUCCESS: {
      return {
        ...state,
        loadingData: false,
        optaPlayers: data,
        status: null,
      };
    }
    case GET_API_PLAYERS_ERR: {
      return {
        ...state,
        loadingData: false,
        status,
      };
    }
    case UPDATE_PLAYER_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_PLAYER_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: data,
        status,
      };
    }
    case UPDATE_PLAYER_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case SELECT_PLAYER_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case SELECT_PLAYER_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: data,
        status,
      };
    }
    case SELECT_PLAYER_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case DELETE_PLAYER_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_PLAYER_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: data,
        status,
      };
    }
    case DELETE_PLAYER_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    default:
      return state;
  }
};

export default PlayerReducer;
