import actions from './actions';

const {
  ADD_FILES_ERR,
  ADD_FILES_SUCCESS,
  ADD_FILES_BEGIN,
  ADD_FOLDER_ERR,
  ADD_FOLDER_SUCCESS,
  ADD_FOLDER_BEGIN,
  GET_FOLDER_LIST_ERR,
  GET_FOLDER_LIST_SUCCESS,
  GET_FOLDER_LIST_BEGIN,
  GET_FILE_LIST_BEGIN,
  GET_FILE_LIST_SUCCESS,
  GET_FILE_LIST_ERR,
  DELETE_FILE_ERR,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_BEGIN,
  CHANGE_SELECTION,
} = actions;

const initState = {
  loading: false,
  loadingFiles: false,
  list: [],
  status: null,
  selected: 0,
  fileList: [],
};

const FolderReducer = (state = initState, action) => {
  const { type, data, status, selected } = action;

  switch (type) {
    case CHANGE_SELECTION: {
      return {
        ...state,
        selected,
      };
    }
    case GET_FILE_LIST_BEGIN: {
      return {
        ...state,
        loadingFiles: true,
      };
    }
    case GET_FILE_LIST_SUCCESS: {
      return {
        ...state,
        loadingFiles: false,
        fileList: data,
        status: null,
      };
    }
    case GET_FILE_LIST_ERR: {
      return {
        ...state,
        loadingFiles: false,
        status,
      };
    }
    case DELETE_FILE_BEGIN: {
      return {
        ...state,
        loadingFiles: true,
      };
    }
    case DELETE_FILE_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case DELETE_FILE_SUCCESS: {
      return {
        ...state,
        loadingFiles: false,
        status,
        fileList: data,
      };
    }
    case ADD_FILES_BEGIN: {
      return {
        ...state,
        loadingFiles: true,
      };
    }
    case ADD_FILES_ERR: {
      return {
        ...state,
        loadingFiles: false,
        status,
      };
    }
    case ADD_FILES_SUCCESS: {
      return {
        ...state,
        loadingFiles: false,
        status,
        fileList: data,
      };
    }
    case ADD_FOLDER_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADD_FOLDER_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case ADD_FOLDER_SUCCESS: {
      return {
        ...state,
        loading: false,
        status,
        list: data,
      };
    }
    case GET_FOLDER_LIST_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_FOLDER_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: data,
        status: null,
      };
    }
    case GET_FOLDER_LIST_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    default:
      return state;
  }
};

export { FolderReducer };
