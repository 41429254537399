import actions from './actions';
import * as api from '../../api/index.js';

const {
  loginBegin,
  loginSuccess,
  loginErr,
  logoutBegin,
  logoutSuccess,
  logoutErr,
  refreshBegin,
  refreshSuccess,
  refreshErr,
  resetPasswordBegin,
  resetPasswordSuccess,
  resetPasswordErr,
  resetClientPasswordBegin,
  resetClientPasswordSuccess,
  resetClientPasswordErr,
} = actions;

const login = (formData, history) => {
  return async dispatch => {
    try {
      dispatch(loginBegin());

      const { data } = await api.signIn(formData);

      if (data.status == 'error') return dispatch(loginErr(data));

      history.push('/dashboard');
      return dispatch(loginSuccess(data));
    } catch (err) {
      dispatch(loginErr({ status: 'error', message: 'Błąd połączenia z serwerem, spróbuj ponownie później.' }));
    }
  };
};

const resetPassword = (email, history) => {
  return async dispatch => {
    try {
      dispatch(resetPasswordBegin());

      const { data } = await api.resetPassword(email);

      if (data.status === 'error') return dispatch(resetPasswordErr(data));

      history.push('/');
      return dispatch(resetPasswordSuccess(data));
    } catch (err) {
      dispatch(resetPasswordErr({ status: 'error', message: 'Błąd połączenia z serwerem, spróbuj ponownie później.' }));
    }
  };
};

const resetClientPassword = (email) => {
  return async dispatch => {
    try {
      dispatch(resetClientPasswordBegin());
      const { data } = await api.resetClientPassword(email);

      if (data.status === 'success') {
        dispatch(resetClientPasswordSuccess(data));
        return;
      }

      dispatch(resetClientPasswordErr(data));
    } catch (err) {
      dispatch(
        resetClientPasswordErr({ status: 'error', message: 'Błąd połączenia z serwerem, spróbuj ponownie później.' }),
      );
    }
  };
};

const refreshToken = data => {
  return async dispatch => {
    return dispatch(refreshSuccess(data));
  };
};

const logOut = () => {
  return async dispatch => {
    try {
      dispatch(logoutBegin());
      dispatch(logoutSuccess(null));
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut, refreshToken, resetPassword, resetClientPassword };
