import { theme, darkTheme } from './theme/themeVariables';

export const themeConfig = {
  darkMode: false,
  topMenu: false,
  rtl: false,
  theme,
  darkTheme,
};

export const editorInitialConfig = {
  language: 'pl',
  height: 500,
  menubar: 'file edit insert view format table tools help',
  plugins: ['advlist', 'lists', 'media', 'image', 'table', 'code', 'link'],
  extended_valid_elements: 'script[language|type|src]',
  toolbar:
    'undo redo | formatselect | code | link | ' +
    'bold italic backcolor | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist outdent indent image | ' +
    'removeformat | help',
  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
};
