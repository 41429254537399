import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

// Imports.
import Dashboard from './dashboard';
import Pages from './pages';
import Users from './users';
import Teams from './teams';
import Players from './players';
import News from './news';
import Matchroom from './matchroom';
import Menu from './menu';
import Clients from './clients';
import Movies from './movies';
import TicketPrices from './ticket-prices';

import withAdminLayout from '../../layout/withAdminLayout';

const Admin = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path={path} component={Dashboard} />
        <Route path={`${path}`} component={Pages} />
        <Route path={`${path}/news`} component={News} />
        <Route path={`${path}/users`} component={Users} />
        <Route path={`${path}/teams`} component={Teams} />
        <Route path={`${path}/players`} component={Players} />
        <Route path={`${path}/matchroom`} component={Matchroom} />
        <Route path={`${path}/menu`} component={Menu} />
        <Route path={`${path}/clients`} component={Clients} />
        <Route path={`${path}/jagatv`} component={Movies} />
        <Route path={`${path}/ticket-prices`} component={TicketPrices} />
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Admin);
