import { combineReducers } from 'redux';
import themeUsersReducer from './themeUsers/reducers';
import { readMessageReducer } from './message/reducers';
import { readNotificationReducer } from './notification/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import { teamReducer } from './team/reducers';
import { sellersReducer } from './sellers/reducers';
import { headerSearchReducer } from './headerSearch/reducers';
import orderReducer from './orders/reducers';
import galleryReducer from './gallary/reducers';
import chartContentReducer from './chartContent/reducers';
import { emailReducer, SingleEmailReducer } from './email/reducers';
import { productReducer, SingleProductReducer } from './product/reducers';
import { chatReducer, SingleChatReducer, groupChatReducer, SingleChatGroupReducer } from './chat/reducers';
import { projectReducer, SingleProjectReducer } from './project/reducers';
import cartData from './cart/reducers';
import Todo from './todo/reducers';
import Note from './note/reducers';
import Task from './task/reducers';
import kanbanBoardReducer from './kanban/reducers';
import Contact from './contact/reducers';
import Profile from './profile/reducers';
import Calender from './calendar/reducers';
import FileManager from './fileManager/reducers';
import { axiosCrudReducer, axiosSingleCrudReducer } from './crud/axios/reducers';

// used reducers
import authReducer from './authentication/reducers';
import userReducer from './users/reducers';
import clientReducer from './clients/reducers';
import playerReducer from './players/reducers';
import { FolderReducer } from './fileManage/reducers';
import { NewsReducer } from './news/reducers';
import teamsReducer from './teams/reducers';
import timetableReducer from './timetable/reducers';
import menuReducer from './menu/reducers';
import { PagesReducer } from './pages/reducers';
import settingsReducer from './settings/reducers';
import moviesReducer from './movies/reducers';
import matchroomReducer from './matchroom/reducers';
import ticketPrices from './ticketPrices/reducers';

const rootReducers = combineReducers({
  auth: authReducer,
  users: userReducer,
  clients: clientReducer,
  players: playerReducer,
  folders: FolderReducer,
  news: NewsReducer,
  teams: teamsReducer,
  timetable: timetableReducer,
  menu: menuReducer,
  pages: PagesReducer,
  settings: settingsReducer,
  movies: moviesReducer,
  matchroom: matchroomReducer,
  tickets: ticketPrices,
  //
  themeUsers: themeUsersReducer,
  headerSearchData: headerSearchReducer,
  message: readMessageReducer,
  notification: readNotificationReducer,
  orders: orderReducer,
  sellers: sellersReducer,
  team: teamReducer,
  gallery: galleryReducer,
  email: emailReducer,
  emailSingle: SingleEmailReducer,
  products: productReducer,
  product: SingleProductReducer,
  chatSingle: SingleChatReducer,
  chatSingleGroup: SingleChatGroupReducer,
  chat: chatReducer,
  groupChat: groupChatReducer,
  projects: projectReducer,
  project: SingleProjectReducer,
  ChangeLayoutMode,
  chartContent: chartContentReducer,
  cart: cartData,
  Todo,
  Note,
  Task,
  KanbanBoard: kanbanBoardReducer,
  Contact,
  Profile,
  Calender,
  FileManager,
  AxiosCrud: axiosCrudReducer,
  SingleAxiosCrud: axiosSingleCrudReducer,
});

export default rootReducers;
