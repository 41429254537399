import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const Menu = lazy(() => import('../../container/menu/Menu'));
const Pages = lazy(() => import('../../container/menu/Pages'));
const AddPage = lazy(() => import('../../container/menu/AddPage'));

const PagesRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/pages/edit/:id`} component={AddPage} />
      <Route path={`${path}/pages/add-page`} component={AddPage} />
      <Route path={`${path}/pages`} component={Pages} />
      <Route exact path={`${path}`} component={Menu} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default PagesRoute;
