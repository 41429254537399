import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Clients = lazy(() => import('../../container/clients/Clients'));
const AddClient = lazy(() => import('../../container/clients/AddWizard/AddClient'));
const NotFound = lazy(() => import('../../container/pages/404'));

const PagesRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/add-client`} component={AddClient} />
      <Route exact path={`${path}`} component={Clients} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default PagesRoute;
