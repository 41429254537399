import actions from './actions';

const {
  ADD_MOVIE_BEGIN,
  ADD_MOVIE_ERR,
  ADD_MOVIE_SUCCESS,
  GET_MOVIES_SUCCESS,
  GET_MOVIES_BEGIN,
  GET_MOVIES_ERR,
  UPDATE_MOVIE_BEGIN,
  UPDATE_MOVIE_SUCCESS,
  UPDATE_MOVIE_ERR,
  DELETE_MOVIE_BEGIN,
  DELETE_MOVIE_SUCCESS,
  DELETE_MOVIE_ERR,
} = actions;

const initState = {
  loading: false,
  list: [],
  status: null,
};

const MovieReducer = (state = initState, action) => {
  const { type, data, status } = action;

  switch (type) {
    case ADD_MOVIE_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADD_MOVIE_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case ADD_MOVIE_SUCCESS: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case GET_MOVIES_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_MOVIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: data,
        status: null,
      };
    }
    case GET_MOVIES_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case UPDATE_MOVIE_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_MOVIE_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: data,
        status,
      };
    }
    case UPDATE_MOVIE_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case DELETE_MOVIE_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_MOVIE_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: data,
        status,
      };
    }
    case DELETE_MOVIE_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    default:
      return state;
  }
};

export default MovieReducer;
