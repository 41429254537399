import { notification } from 'antd';

const sendNotification = (status, message) => {
  notification[status]({
    message: status == 'success' ? 'Udało się' : 'Błąd',
    description: message,
  });
};

const actions = {
  ADD_TEAM_SUCCESS: 'ADD_TEAM_SUCCESS',
  ADD_TEAM_ERR: 'ADD_TEAM_ERR',
  ADD_TEAM_BEGIN: 'ADD_TEAM_BEGIN',

  GET_TEAMS_BEGIN: 'GET_TEAMS_BEGIN',
  GET_TEAMS_ERR: 'GET_TEAMS_ERR',
  GET_TEAMS_SUCCESS: 'GET_TEAMS_SUCCESS',

  GET_LEAGUE_TEAMS_BEGIN: 'GET_LEAGUE_TEAMS_BEGIN',
  GET_LEAGUE_TEAMS_SUCCESS: 'GET_LEAGUE_TEAMS_SUCCESS',
  GET_LEAGUE_TEAMS_ERR: 'GET_LEAGUE_TEAMS_ERR',

  UPDATE_TEAM_BEGIN: 'UPDATE_TEAM_BEGIN',
  UPDATE_TEAM_SUCCESS: 'UPDATE_TEAM_SUCCESS',
  UPDATE_TEAM_ERR: 'UPDATE_TEAM_ERR',

  DELETE_TEAM_BEGIN: 'DELETE_TEAM_BEGIN',
  DELETE_TEAM_SUCCESS: 'DELETE_TEAM_SUCCESS',
  DELETE_TEAM_ERR: 'DELETE_TEAM_ERR',

  DELETE_SEASON_BEGIN: 'DELETE_SEASON_BEGIN',
  DELETE_SEASON_SUCCESS: 'DELETE_SEASON_SUCCESS',
  DELETE_SEASON_ERR: 'DELETE_SEASON_ERR',

  addTeamBegin: () => {
    return {
      type: actions.ADD_TEAM_BEGIN,
    };
  },
  addTeamErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.ADD_TEAM_ERR,
      status,
    };
  },
  addTeamSuccess: (status, data) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.ADD_TEAM_SUCCESS,
      status,
      data,
    };
  },

  getLeagueTeamsBegin: () => {
    return {
      type: actions.GET_LEAGUE_TEAMS_BEGIN,
    };
  },
  getLeagueTeamsSuccess: data => {
    return {
      type: actions.GET_LEAGUE_TEAMS_SUCCESS,
      data,
    };
  },
  getLeagueTeamsErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.GET_LEAGUE_TEAMS_ERR,
      status,
    };
  },

  getTeamsBegin: () => {
    return {
      type: actions.GET_TEAMS_BEGIN,
    };
  },
  getTeamsErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.GET_TEAMS_ERR,
      status,
    };
  },
  getTeamsSuccess: data => {
    return {
      type: actions.GET_TEAMS_SUCCESS,
      data,
    };
  },
  updateTeamBegin: () => {
    return {
      type: actions.UPDATE_TEAM_BEGIN,
    };
  },
  updateTeamSuccess: (data, status) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.UPDATE_TEAM_SUCCESS,
      data,
      status,
    };
  },
  updateTeamErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.UPDATE_TEAM_ERR,
      status,
    };
  },
  addSeasonBegin: () => {
    return {
      type: actions.UPDATE_TEAM_BEGIN,
    };
  },
  addSeasonSuccess: (data, status) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.UPDATE_TEAM_SUCCESS,
      data,
      status,
    };
  },
  addSeasonErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.UPDATE_TEAM_ERR,
      status,
    };
  },
  activateSeasonBegin: () => {
    return {
      type: actions.UPDATE_TEAM_BEGIN,
    };
  },
  activateSeasonSuccess: (data, status) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.UPDATE_TEAM_SUCCESS,
      data,
      status,
    };
  },
  activateSeasonErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.UPDATE_TEAM_ERR,
      status,
    };
  },
  deleteTeamBegin: () => {
    return {
      type: actions.DELETE_TEAM_BEGIN,
    };
  },
  deleteTeamSuccess: (data, status) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.DELETE_TEAM_SUCCESS,
      data,
      status,
    };
  },
  deleteTeamErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.DELETE_TEAM_ERR,
      status,
    };
  },
  deleteSeasonBegin: () => {
    return {
      type: actions.DELETE_SEASON_BEGIN,
    };
  },
  deleteSeasonSuccess: (data, status) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.DELETE_SEASON_SUCCESS,
      data,
      status,
    };
  },
  deleteSeasonErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.DELETE_SEASON_ERR,
      status,
    };
  },
};

export default actions;
