import { notification } from 'antd';

const sendNotification = (status, message) => {
  notification[status]({
    message: status == 'success' ? 'Udało się' : 'Błąd',
    description: message,
  });
};

const actions = {
  ADD_MATCH_SUCCESS: 'ADD_MATCH_SUCCESS',
  ADD_MATCH_ERR: 'ADD_MATCH_ERR',
  ADD_MATCH_BEGIN: 'ADD_MATCH_BEGIN',

  GET_TIMETABLE_BEGIN: 'GET_TIMETABLE_BEGIN',
  GET_TIMETABLE_ERR: 'GET_TIMETABLE_ERR',
  GET_TIMETABLE_SUCCESS: 'GET_TIMETABLE_SUCCESS',

  UPDATE_MATCH_BEGIN: 'UPDATE_MATCH_BEGIN',
  UPDATE_MATCH_SUCCESS: 'UPDATE_MATCH_SUCCESS',
  UPDATE_MATCH_ERR: 'UPDATE_MATCH_ERR',

  DELETE_MATCH_BEGIN: 'DELETE_MATCH_BEGIN',
  DELETE_MATCH_SUCCESS: 'DELETE_MATCH_SUCCESS',
  DELETE_MATCH_ERR: 'DELETE_MATCH_ERR',

  addMatchBegin: () => {
    return {
      type: actions.ADD_MATCH_BEGIN,
    };
  },
  addMatchErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.ADD_MATCH_ERR,
      status,
    };
  },
  addMatchSuccess: (status, data) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.ADD_MATCH_SUCCESS,
      status,
      data,
    };
  },
  getTimetableBegin: () => {
    return {
      type: actions.GET_TIMETABLE_BEGIN,
    };
  },
  getTimetableErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.GET_TIMETABLE_ERR,
      status,
    };
  },
  getTimetableSuccess: data => {
    return {
      type: actions.GET_TIMETABLE_SUCCESS,
      data,
    };
  },
  updateMatchBegin: () => {
    return {
      type: actions.UPDATE_MATCH_BEGIN,
    };
  },
  updateMatchSuccess: (data, status) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.UPDATE_MATCH_SUCCESS,
      data,
      status,
    };
  },
  updateMatchErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.UPDATE_MATCH_ERR,
      status,
    };
  },
  addSeasonBegin: () => {
    return {
      type: actions.UPDATE_MATCH_BEGIN,
    };
  },
  deleteMatchBegin: () => {
    return {
      type: actions.DELETE_MATCH_BEGIN,
    };
  },
  deleteMatchSuccess: (data, status) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.DELETE_MATCH_SUCCESS,
      data,
      status,
    };
  },
  deleteMatchErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.DELETE_MATCH_ERR,
      status,
    };
  },
};

export default actions;
