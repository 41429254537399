import { notification } from 'antd';

const sendNotification = (status, message) => {
  notification[status]({
    message: status == 'success' ? 'Udało się' : 'Błąd',
    description: message,
  });
};

const actions = {
  ADD_PLAYER_SUCCESS: 'ADD_PLAYER_SUCCESS',
  ADD_PLAYER_ERR: 'ADD_PLAYER_ERR',
  ADD_PLAYER_BEGIN: 'ADD_PLAYER_BEGIN',

  GET_PLAYERS_BEGIN: 'GET_PLAYERS_BEGIN',
  GET_PLAYERS_ERR: 'GET_PLAYERS_ERR',
  GET_PLAYERS_SUCCESS: 'GET_PLAYERS_SUCCESS',

  GET_API_PLAYERS_BEGIN: 'GET_API_PLAYERS_BEGIN',
  GET_API_PLAYERS_ERR: 'GET_API_PLAYERS_ERR',
  GET_API_PLAYERS_SUCCESS: 'GET_API_PLAYERS_SUCCESS',

  UPDATE_PLAYER_BEGIN: 'UPDATE_PLAYER_BEGIN',
  UPDATE_PLAYER_SUCCESS: 'UPDATE_PLAYER_SUCCESS',
  UPDATE_PLAYER_ERR: 'UPDATE_PLAYER_ERR',

  SELECT_PLAYER_BEGIN: 'SELECT_PLAYER_BEGIN',
  SELECT_PLAYER_SUCCESS: 'SELECT_PLAYER_SUCCESS',
  SELECT_PLAYER_ERR: 'SELECT_PLAYER_ERR',

  DELETE_PLAYER_BEGIN: 'DELETE_PLAYER_BEGIN',
  DELETE_PLAYER_SUCCESS: 'DELETE_PLAYER_SUCCESS',
  DELETE_PLAYER_ERR: 'DELETE_PLAYER_ERR',

  addPlayerBegin: () => {
    return {
      type: actions.ADD_PLAYER_BEGIN,
    };
  },
  addPlayerErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.ADD_PLAYER_ERR,
      status,
    };
  },
  addPlayerSuccess: status => {
    return {
      type: actions.ADD_PLAYER_SUCCESS,
      status,
    };
  },

  getOptaPlayersBegin: () => {
    return {
      type: actions.GET_API_PLAYERS_BEGIN,
    };
  },
  getOptaPlayersErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.GET_API_PLAYERS_ERR,
      status,
    };
  },
  getOptaPlayersSuccess: data => {
    return {
      type: actions.GET_API_PLAYERS_SUCCESS,
      data,
    };
  },

  getPlayersBegin: () => {
    return {
      type: actions.GET_PLAYERS_BEGIN,
    };
  },
  getPlayersErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.GET_PLAYERS_ERR,
      status,
    };
  },
  getPlayersSuccess: data => {
    return {
      type: actions.GET_PLAYERS_SUCCESS,
      data,
    };
  },
  updatePlayerBegin: () => {
    return {
      type: actions.UPDATE_PLAYER_BEGIN,
    };
  },
  updatePlayerSuccess: (data, status) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.UPDATE_PLAYER_SUCCESS,
      data,
      status,
    };
  },
  updatePlayerErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.UPDATE_PLAYER_ERR,
      status,
    };
  },
  selectPlayerBegin: () => {
    return {
      type: actions.SELECT_PLAYER_BEGIN,
    };
  },
  selectPlayerSuccess: (data, status) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.SELECT_PLAYER_SUCCESS,
      data,
      status,
    };
  },
  selectPlayerErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.SELECT_PLAYER_ERR,
      status,
    };
  },
  deletePlayerBegin: () => {
    return {
      type: actions.DELETE_PLAYER_BEGIN,
    };
  },
  deletePlayerSuccess: (data, status) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.DELETE_PLAYER_SUCCESS,
      data,
      status,
    };
  },
  deletePlayerErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.DELETE_PLAYER_ERR,
      status,
    };
  },
};

export default actions;
