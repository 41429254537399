import { notification } from 'antd';

const sendNotification = (status, message) => {
  notification[status]({
    message: status == 'success' ? 'Udało się' : 'Błąd',
    description: message,
  });
};

const actions = {
  ADD_MOVIE_SUCCESS: 'ADD_MOVIE_SUCCESS',
  ADD_MOVIE_ERR: 'ADD_MOVIE_ERR',
  ADD_MOVIE_BEGIN: 'ADD_MOVIE_BEGIN',

  GET_MOVIES_BEGIN: 'GET_MOVIES_BEGIN',
  GET_MOVIES_ERR: 'GET_MOVIES_ERR',
  GET_MOVIES_SUCCESS: 'GET_MOVIES_SUCCESS',

  UPDATE_MOVIE_BEGIN: 'UPDATE_MOVIE_BEGIN',
  UPDATE_MOVIE_SUCCESS: 'UPDATE_MOVIE_SUCCESS',
  UPDATE_MOVIE_ERR: 'UPDATE_MOVIE_ERR',

  DELETE_MOVIE_BEGIN: 'DELETE_MOVIE_BEGIN',
  DELETE_MOVIE_SUCCESS: 'DELETE_MOVIE_SUCCESS',
  DELETE_MOVIE_ERR: 'DELETE_MOVIE_ERR',

  addMovieBegin: () => {
    return {
      type: actions.ADD_MOVIE_BEGIN,
    };
  },
  addMovieErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.ADD_MOVIE_ERR,
      status,
    };
  },
  addMovieSuccess: (status, data) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.ADD_MOVIE_SUCCESS,
      data,
      status,
    };
  },
  getMoviesBegin: () => {
    return {
      type: actions.GET_MOVIES_BEGIN,
    };
  },
  getMoviesErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.GET_MOVIES_ERR,
      status,
    };
  },
  getMoviesSuccess: data => {
    return {
      type: actions.GET_MOVIES_SUCCESS,
      data,
    };
  },
  updateMovieBegin: () => {
    return {
      type: actions.UPDATE_MOVIE_BEGIN,
    };
  },
  updateMovieSuccess: (data, status) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.UPDATE_MOVIE_SUCCESS,
      data,
      status,
    };
  },
  updateMovieErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.UPDATE_MOVIE_ERR,
      status,
    };
  },
  deleteMovieBegin: () => {
    return {
      type: actions.DELETE_MOVIE_BEGIN,
    };
  },
  deleteMovieSuccess: (data, status) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.DELETE_MOVIE_SUCCESS,
      data,
      status,
    };
  },
  deleteMovieErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.DELETE_MOVIE_ERR,
      status,
    };
  },
};

export default actions;
