import actions from './actions';

const {
  ADD_CLIENT_BEGIN,
  ADD_CLIENT_ERR,
  ADD_CLIENT_SUCCESS,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_BEGIN,
  GET_CLIENTS_ERR,
  UPDATE_CLIENT_BEGIN,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_ERR,
  DELETE_CLIENT_BEGIN,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_ERR,
} = actions;

const initState = {
  loading: false,
  list: [],
  status: null,
};

const ClientReducer = (state = initState, action) => {
  const { type, data, status } = action;

  switch (type) {
    case ADD_CLIENT_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADD_CLIENT_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case ADD_CLIENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case GET_CLIENTS_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_CLIENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: data,
        status: null,
      };
    }
    case GET_CLIENTS_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case UPDATE_CLIENT_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_CLIENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: data,
        status,
      };
    }
    case UPDATE_CLIENT_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case DELETE_CLIENT_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_CLIENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: data,
        status,
      };
    }
    case DELETE_CLIENT_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    default:
      return state;
  }
};

export default ClientReducer;
