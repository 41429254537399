import actions from './actions';

const {
  ADD_USER_BEGIN,
  ADD_USER_ERR,
  ADD_USER_SUCCESS,
  GET_USERS_SUCCESS,
  GET_USERS_BEGIN,
  GET_USERS_ERR,
  UPDATE_USER_BEGIN,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERR,
  DELETE_USER_BEGIN,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERR,
} = actions;

const initState = {
  loading: false,
  list: [],
  status: null,
};

const UserReducer = (state = initState, action) => {
  const { type, data, status } = action;

  switch (type) {
    case ADD_USER_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADD_USER_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case ADD_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case GET_USERS_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_USERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: data,
        status: null,
      };
    }
    case GET_USERS_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case UPDATE_USER_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: data,
        status,
      };
    }
    case UPDATE_USER_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case DELETE_USER_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: data,
        status,
      };
    }
    case DELETE_USER_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    default:
      return state;
  }
};

export default UserReducer;
