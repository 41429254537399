import { notification } from 'antd';

const sendNotification = (status, message) => {
  notification[status]({
    message: status == 'success' ? 'Udało się' : 'Błąd',
    description: message,
  });
};

const actions = {
  ADD_CATEGORY_BEGIN: 'ADD_CATEGORY_BEGIN',
  ADD_CATEGORY_SUCCESS: 'ADD_CATEGORY_SUCCESS',
  ADD_CATEGORY_ERR: 'ADD_CATEGORY_ERR',

  GET_CATEGORIES_BEGIN: 'GET_CATEGORIES_BEGIN',
  GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_ERR: 'GET_CATEGORIES_ERR',

  ADD_NEWS_BEGIN: 'ADD_NEWS_BEGIN',
  ADD_NEWS_SUCCESS: 'ADD_NEWS_SUCCESS',
  ADD_NEWS_ERR: 'ADD_NEWS_ERR',

  GET_ONE_NEWS_BEGIN: 'GET_ONE_NEWS_BEGIN',
  GET_ONE_NEWS_SUCCESS: 'GET_ONE_NEWS_SUCCESS',
  GET_ONE_NEWS_ERR: 'GET_ONE_NEWS_ERR',

  EDIT_NEWS_BEGIN: 'EDIT_NEWS_BEGIN',
  EDIT_NEWS_SUCCESS: 'EDIT_NEWS_SUCCESS',
  EDIT_NEWS_ERR: 'EDIT_NEWS_ERR',

  GET_NEWS_BEGIN: 'GET_NEWS_BEGIN',
  GET_NEWS_SUCCESS: 'GET_NEWS_SUCCESS',
  GET_NEWS_ERR: 'GET_NEWS_ERR',

  DELETE_NEWS_BEGIN: 'DELETE_NEWS_BEGIN',
  DELETE_NEWS_SUCCESS: 'DELETE_NEWS_SUCCESS',
  DELETE_NEWS_ERR: 'DELETE_NEWS_ERR',

  addCategoryBegin: () => {
    return {
      type: actions.ADD_CATEGORY_BEGIN,
    };
  },
  addCategorySuccess: (data, status) => {
    sendNotification(status.status, status.message);

    return {
      type: actions.ADD_CATEGORY_SUCCESS,
      data,
      status,
    };
  },
  addCategoryErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.ADD_CATEGORY_ERR,
      status,
    };
  },

  getCategoriesBegin: () => {
    return {
      type: actions.GET_CATEGORIES_BEGIN,
    };
  },
  getCategoriesSuccess: data => {
    return {
      type: actions.GET_CATEGORIES_SUCCESS,
      data,
    };
  },
  getCategoriesErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.GET_CATEGORIES_ERR,
      status,
    };
  },

  addNewsBegin: () => {
    return {
      type: actions.ADD_NEWS_BEGIN,
    };
  },
  addNewsSuccess: () => {
    return {
      type: actions.ADD_NEWS_SUCCESS,
      status,
    };
  },
  addNewsErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.ADD_NEWS_ERR,
      status,
    };
  },

  getNewsBegin: () => {
    return {
      type: actions.GET_NEWS_BEGIN,
    };
  },
  getNewsSuccess: data => {
    return {
      type: actions.GET_NEWS_SUCCESS,
      data,
    };
  },
  getNewsErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.GET_NEWS_ERR,
      status,
    };
  },

  getOneNewsBegin: () => {
    return {
      type: actions.GET_ONE_NEWS_BEGIN,
    };
  },
  getOneNewsSuccess: data => {
    return {
      type: actions.GET_ONE_NEWS_SUCCESS,
      data,
    };
  },
  getOneNewsErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.GET_ONE_NEWS_ERR,
      status,
    };
  },

  editNewsBegin: () => {
    return {
      type: actions.EDIT_NEWS_BEGIN,
    };
  },
  editNewsSuccess: (data, status) => {
    return {
      type: actions.EDIT_NEWS_SUCCESS,
      data,
      status,
    };
  },
  editNewsErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.EDIT_NEWS_ERR,
      status,
    };
  },

  deleteNewsBegin: () => {
    return {
      type: actions.DELETE_NEWS_BEGIN,
    };
  },
  deleteNewsSuccess: (data, status) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.DELETE_NEWS_SUCCESS,
      data,
      status,
    };
  },
  deleteNewsErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.DELETE_NEWS_ERR,
      status,
    };
  },
};

export default actions;
