import Cookies from 'js-cookie';
import actions from './actions';

const {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_ERR,
  LOGOUT_BEGIN,
  LOGOUT_SUCCESS,
  LOGOUT_ERR,
  LOGOUT_SESSION,
  REFRESH_ERR,
  REFRESH_SUCCESS,
  REFRESH_BEGIN,
  PASSWORD_RESET_BEGIN,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_ERR,
  RESET_CLIENT_PASSWORD_BEGIN,
  RESET_CLIENT_PASSWORD_SUCCESS,
  RESET_CLIENT_PASSWORD_ERR,
} = actions;

const initState = {
  login: JSON.parse(localStorage.getItem('user')),
  loading: false,
  error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem('user', JSON.stringify(data));

      return {
        ...state,
        login: JSON.parse(localStorage.getItem('user')),
        loading: false,
      };
    case LOGIN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      localStorage.removeItem('user');

      return {
        ...state,
        login: null,
        loading: false,
      };
    case LOGOUT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LOGOUT_SESSION:
      return {
        ...state,
        login: null,
        loading: false,
      };
    case REFRESH_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case REFRESH_SUCCESS:
      localStorage.setItem('user', JSON.stringify(data));

      return {
        ...state,
        login: JSON.parse(localStorage.getItem('user')),
        loading: false,
      };
    case REFRESH_ERR:
      localStorage.removeItem('user');

      return {
        ...state,
        error: err,
        login: null,
        loading: false,
      };
    case PASSWORD_RESET_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PASSWORD_RESET_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case RESET_CLIENT_PASSWORD_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case RESET_CLIENT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case RESET_CLIENT_PASSWORD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default AuthReducer;
