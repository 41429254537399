import actions from './actions';

const {
  GET_PRICELIST_BEGIN,
  GET_PRICELIST_SUCCESS,
  GET_PRICELIST_ERR,
  ADD_PRICELIST_SUCCESS,
  ADD_PRICELIST_ERR,
  ADD_PRICELIST_BEGIN,
  UPDATE_PRICELIST_BEGIN,
  UPDATE_PRICELIST_SUCCESS,
  UPDATE_PRICELIST_ERR,
  DELETE_PRICELIST_BEGIN,
  DELETE_PRICELIST_SUCCESS,
  DELETE_PRICELIST_ERR,
  ADD_PRICE_ELEMENT_SUCCESS,
  ADD_PRICE_ELEMENT_ERR,
  ADD_PRICE_ELEMENT_BEGIN,
  UPDATE_PRICE_ELEMENT_BEGIN,
  UPDATE_PRICE_ELEMENT_SUCCESS,
  UPDATE_PRICE_ELEMENT_ERR,
  DELETE_PRICE_ELEMENT_BEGIN,
  DELETE_PRICE_ELEMENT_SUCCESS,
  DELETE_PRICE_ELEMENT_ERR,
} = actions;

const initState = {
  loading: false,
  list: [],
  status: null,
};

const PriceListReducer = (state = initState, action) => {
  const { type, data, status } = action;

  switch (type) {
    case GET_PRICELIST_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_PRICELIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: data,
        status: null,
      };
    }
    case GET_PRICELIST_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }

    case ADD_PRICELIST_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADD_PRICELIST_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case ADD_PRICELIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        status,
        list: data,
      };
    }
    case UPDATE_PRICELIST_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_PRICELIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: data,
        status,
      };
    }
    case UPDATE_PRICELIST_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case DELETE_PRICELIST_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_PRICELIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: data,
        status,
      };
    }
    case DELETE_PRICELIST_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }

    case ADD_PRICE_ELEMENT_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADD_PRICE_ELEMENT_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case ADD_PRICE_ELEMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        status,
        list: data,
      };
    }
    case UPDATE_PRICE_ELEMENT_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_PRICE_ELEMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: data,
        status,
      };
    }
    case UPDATE_PRICE_ELEMENT_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case DELETE_PRICE_ELEMENT_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_PRICE_ELEMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: data,
        status,
      };
    }
    case DELETE_PRICE_ELEMENT_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }

    default:
      return state;
  }
};

export default PriceListReducer;
