import actions from './actions';

const {
  ADD_PAGE_BEGIN,
  ADD_PAGE_SUCCESS,
  ADD_PAGE_ERR,
  GET_PAGES_BEGIN,
  GET_PAGES_SUCCESS,
  GET_PAGES_ERR,
  GET_ONE_PAGE_BEGIN,
  GET_ONE_PAGE_SUCCESS,
  GET_ONE_PAGE_ERR,
  EDIT_PAGE_BEGIN,
  EDIT_PAGE_SUCCESS,
  EDIT_PAGE_ERR,
  DELETE_PAGE_BEGIN,
  DELETE_PAGE_SUCCESS,
  DELETE_PAGE_ERR,
} = actions;

const initState = {
  loading: false,
  loadingData: false,
  pages: [],
  status: null,
  toEdit: null,
};

const PagesReducer = (state = initState, action) => {
  const { type, data, status } = action;

  switch (type) {
    // ADD PAGE CASES

    case ADD_PAGE_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADD_PAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case ADD_PAGE_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }

    // GET PAGE CASES

    case GET_PAGES_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_PAGES_SUCCESS: {
      return {
        ...state,
        loading: false,
        pages: data,
        status: null,
      };
    }
    case GET_PAGES_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }

    // GET ONE PAGE CASES

    case GET_ONE_PAGE_BEGIN: {
      return {
        ...state,
        loadingData: true,
      };
    }
    case GET_ONE_PAGE_SUCCESS: {
      return {
        ...state,
        loadingData: false,
        toEdit: data,
        status: null,
      };
    }
    case GET_ONE_PAGE_ERR: {
      return {
        ...state,
        loadingData: false,
        status,
      };
    }

    // EDIT PAGE CASES

    case EDIT_PAGE_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case EDIT_PAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        toEdit: data,
        status,
      };
    }
    case EDIT_PAGE_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }

    // DELETE PAGE CASES

    case DELETE_PAGE_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_PAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        pages: data,
        status,
      };
    }
    case DELETE_PAGE_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    default:
      return state;
  }
};

export { PagesReducer };
