import { notification } from 'antd';

const sendNotification = (status, message) => {
  notification[status]({
    message: status == 'success' ? 'Udało się' : 'Błąd',
    description: message,
  });
};

const actions = {
  ADD_MENU_SUCCESS: 'ADD_MENU_SUCCESS',
  ADD_MENU_ERR: 'ADD_MENU_ERR',
  ADD_MENU_BEGIN: 'ADD_MENU_BEGIN',

  GET_MENU_BEGIN: 'GET_MENU_BEGIN',
  GET_MENU_ERR: 'GET_MENU_ERR',
  GET_MENU_SUCCESS: 'GET_MENU_SUCCESS',

  UPDATE_MENU_BEGIN: 'UPDATE_MENU_BEGIN',
  UPDATE_MENU_SUCCESS: 'UPDATE_MENU_SUCCESS',
  UPDATE_MENU_ERR: 'UPDATE_MENU_ERR',

  DELETE_MENU_BEGIN: 'DELETE_MENU_BEGIN',
  DELETE_MENU_SUCCESS: 'DELETE_MENU_SUCCESS',
  DELETE_MENU_ERR: 'DELETE_MENU_ERR',

  SAVE_POSITION_SUCCESS: 'SAVE_POSITION_SUCCESS',
  SAVE_POSITION_BEGIN: 'SAVE_POSITION_BEGIN',
  SAVE_POSITION_ERR: 'SAVE_POSITION_ERR',

  savePositionBegin: () => {
    return {
      type: actions.SAVE_POSITION_BEGIN,
    };
  },
  savePositionErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.SAVE_POSITION_ERR,
      status,
    };
  },
  savePositionSuccess: (status, data) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.SAVE_POSITION_SUCCESS,
      status,
      data,
    };
  },

  addMenuBegin: () => {
    return {
      type: actions.ADD_MENU_BEGIN,
    };
  },
  addMenuErr: status => {
    return {
      type: actions.ADD_MENU_ERR,
      status,
    };
  },
  addMenuSuccess: (status, data) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.ADD_MENU_SUCCESS,
      status,
      data,
    };
  },
  getMenuBegin: () => {
    return {
      type: actions.GET_MENU_BEGIN,
    };
  },
  getMenuErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.GET_MENU_ERR,
      status,
    };
  },
  getMenuSuccess: data => {
    return {
      type: actions.GET_MENU_SUCCESS,
      data,
    };
  },
  updateMenuBegin: () => {
    return {
      type: actions.UPDATE_MENU_BEGIN,
    };
  },
  updateMenuSuccess: (data, status) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.UPDATE_MENU_SUCCESS,
      data,
      status,
    };
  },
  updateMenuErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.UPDATE_MENU_ERR,
      status,
    };
  },
  deleteMenuBegin: () => {
    return {
      type: actions.DELETE_MENU_BEGIN,
    };
  },
  deleteMenuSuccess: (data, status) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.DELETE_MENU_SUCCESS,
      data,
      status,
    };
  },
  deleteMenuErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.DELETE_MENU_ERR,
      status,
    };
  },
};

export default actions;
