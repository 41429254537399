import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Teams = lazy(() => import('../../container/teams/Teams'));
const Seasons = lazy(() => import('../../container/teams/Seasons'));
const SeasonOne = lazy(() => import('../../container/teams/SeasonOne'));
const Timetable = lazy(() => import('../../container/teams/Timetable'));
const NotFound = lazy(() => import('../../container/pages/404'));

const PagesRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      {/* <Route path={`${path}/add-user`} component={AddUser} /> */}
      <Route path={`${path}/:id/seasons/:season/timetable`} component={Timetable} />
      <Route path={`${path}/:id/seasons/:season`} component={SeasonOne} />
      <Route path={`${path}/:id/seasons`} component={Seasons} />
      <Route exact path={`${path}`} component={Teams} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default PagesRoute;
